import React from "react"
import { Box, Button, Heading, Link, Text, useTheme } from "@chakra-ui/core"
import { Link as GatsbyLink } from "gatsby"

import Layout from "../components/layout"

const Hoidot = props => {
  const theme = useTheme()
  return (
    <Layout>
      <Box w="100%" p="6">
        <Box maxW={theme.breakpoints.lg} mx="auto">
          <Heading textAlign="center" color="pink.800" mb="4">
            Yrittäjä & Kosmetologi
          </Heading>
          <Text color="pink.800" fontSize="lg" mb="6">
            Toimin puolet kolmen vuoden opiskelusta itse oppilaana Onnistua.fi
            -opiskelijahoitolassa, joka perustettiin 2017 lopulla.
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            Näen siis asiat ohjaajana myös oppilaan näkökulmasta. Viimeisen
            vuoden harjoittelin uusien oppilaiden ohjaamista yhdessä yrityksen
            perustajan Tuula Hankamäen kanssa.
          </Text>

          <Text color="pink.800" fontSize="lg" mb="6">
            Kesällä 2019 perustin toiminimen Vilmas lash and beautyn
            opiskelijahoitolan tiloihin. Sain sisustaa itselleni ihanan
            prinsessahuoneen, jonne olet tervetullut rentoutumaan tai muuten
            kaunistautumaan.
          </Text>
          <Text color="pink.800" fontSize="lg" mb="6">
            Onnistua.fi toiminnan ostin joulukuussa 2019. Rakastan työtäni ja
            oppilaita, joita saan ohjata ja kannustaa ylittämään itsensä sekä
            tietysti ennenkaikkea asiakkaitamme. Meillä jokainen saa olla juuri
            oma itsensä, eikä ole olemassa tyhmiä kysymyksiä.{" "}
            <Link as={GatsbyLink} to="/opiskelijat" color="pink.500">
              Lue lisää opiskelijatoiminnasta.
            </Link>
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default Hoidot
